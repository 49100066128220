import moment, { Moment } from 'moment'
import React, { useCallback, useState } from 'react'

import DatePicker from '../../Common/DatePicker'
import DateRangePicker from '../../Common/DateRangePicker'
import DatesPickedText from '../../Common/DatesPickedText'

import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import SearchDateToggle from 'components/Search/SearchForm/SearchDateInput/SearchDateToggle'
import useModalCallbacks from 'tripPlanner/hooks/View/useModalCallbacks'

interface Props {
  initStartDate?: Moment
  initEndDate?: Moment
  initVisibleDate?: Moment
  initIsFlexibleDateSelected?: boolean
  onSetDates: (startDate?: Moment, endDate?: Moment) => void
  onSetFlexibleDates?: (isFlexibleDates: boolean) => void
  minDate?: Moment
  maxDate?: Moment
  isFlights?: boolean
  useSingleDate?: boolean
}

function SearchDatePicker(props: Props) {
  const {
    initStartDate,
    initEndDate,
    initVisibleDate,
    initIsFlexibleDateSelected,
    onSetDates,
    onSetFlexibleDates,
    minDate,
    maxDate,
    useSingleDate,
  } = props

  const { closeModal, goBack } = useModalCallbacks()
  const [isFlexibleDates, setIsFlexibleDates] = useState<boolean>(
    !!initIsFlexibleDateSelected,
  )
  const [startDate, setStartDate] = useState<moment.Moment | undefined>(
    initStartDate,
  )
  const [endDate, setEndDate] = useState<moment.Moment | undefined>(initEndDate)

  const changeStartDate = useCallback(
    (date?: moment.Moment) => {
      setStartDate(date)
      setIsFlexibleDates(false)
    },
    [setStartDate, setIsFlexibleDates],
  )

  const changeEndDate = useCallback(
    (date?: moment.Moment) => {
      setEndDate(date)
      setIsFlexibleDates(false)
    },
    [setEndDate, setIsFlexibleDates],
  )

  const disableIsFlexibleDates = useCallback(() => {
    setIsFlexibleDates(false)
  }, [setIsFlexibleDates])

  const onApply = useCallback(
    (startDate?: Moment, endDate?: Moment) => {
      if (isFlexibleDates) {
        onSetFlexibleDates?.(true)
      } else {
        onSetFlexibleDates?.(false)
        onSetDates(startDate, endDate)
      }

      if (goBack) {
        goBack()
      }
    },
    [goBack, onSetDates, isFlexibleDates, onSetFlexibleDates],
  )

  const enableIsFlexibleDates = useCallback(() => {
    setStartDate(undefined)
    setEndDate(undefined)
    setIsFlexibleDates(true)
    onApply()
  }, [setStartDate, setEndDate, setIsFlexibleDates, onApply])

  const onClickApply = useCallback(
    () => onApply(startDate, endDate),
    [endDate, onApply, startDate],
  )

  return (
    <>
      <ModalHeader
        title={`Pick your date${useSingleDate ? '' : 's'}`}
        onCloseButtonClick={closeModal}
        onBackButtonClick={goBack}
      />
      <ModalBody>
        <ModalContent>
          <VerticalSpacer gap={16}>
            {onSetFlexibleDates && (
              <SearchDateToggle
                onSelectAnytime={enableIsFlexibleDates}
                disableFlexible
                onSelectSpecific={disableIsFlexibleDates}
                anytimeDateSelected={isFlexibleDates}
              />
            )}
            {!useSingleDate && (
              <DateRangePicker
                onChangeStartDate={changeStartDate}
                onChangeEndDate={changeEndDate}
                startDate={startDate}
                initDate={initVisibleDate}
                endDate={endDate}
                minDate={minDate}
                maxDate={maxDate}
                allowSameDay={false}
              />
            )}
            {useSingleDate && (
              <DatePicker
                onDateChange={changeStartDate}
                date={startDate}
                minDate={minDate}
                maxDate={maxDate}
              />
            )}
          </VerticalSpacer>
        </ModalContent>
      </ModalBody>
      <ModalFooter
        primaryActionProps={{
          children: 'Apply',
          onClick: onClickApply,
        }}
      >
        <DatesPickedText start={startDate} end={endDate} />
      </ModalFooter>
    </>
  )
}

export default SearchDatePicker
